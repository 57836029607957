import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import ListaPremioEditar from "../../components/listPrizeEdit";
import { getPremios } from "../../controllers/premioController";
import GlobalStyle, { Container } from "../../styles/global";
import EditarPremio from "./edit";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

function EditPremioAdm({ onClose }) {
  const [step, setStep] = useState(1);
  const [selectedPremio, setSelectedPremio] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [notFound, setNotFound] = useState(false);
  const usuarioId = JSON.parse(localStorage.getItem("user")).idusuario;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await getPremios(usuarioId);

        setSelectedPremio(allData);
      } catch (error) {
        toast.error("Erro ao carregar os prêmios.");
      }
    };

    fetchData();
  }, [usuarioId]);

  console.log(selectedPremio); // Funciona

  useEffect(() => {
    if (selectedPremio.idpremio) {
      navigate(`/premios/premio/editar/${selectedPremio.idpremio}`);
    }
  }, [selectedPremio, navigate]);

  const handleSelectPremio = (premio) => {
    setSelectedPremio(premio);
    setStep(2);
  };

  return (
    <>
      <GlobalStyle />
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        {step === 1 && (
          <ListaPremioEditar
            onSelect={handleSelectPremio}
            onClose={() => navigate("/")}
          />
        )}
        {step === 2 && <EditarPremio premio={selectedPremio} />}
      </Container>
    </>
  );
}

export default EditPremioAdm;
