import React from "react";
import styled from "styled-components";
import logo from "../assets/icons/logo-banco-da-felicidade.svg";
import { TextColorLight } from "../styles/global";
import { Container } from "../styles/styles";

const ConfirmSection = styled.section`
  width: 100%;
  background-color: #34c759;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;

  .curve {
    position: absolute;
    top: -50;
    width: 100%;
    height: 100%;
    background-image: url($#007A47);
    background-size: cover;
    background-position: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    z-index: 10;

    img {
      width: 150px;
    }

    h1 {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: ${TextColorLight};
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  padding: 10px 70px;
  border-radius: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
`;

function ConfirmacaoEditarUser({ onClose }) {
  return (
    <Container direction="column" width="100%" padding="0px">
      <ConfirmSection>
        <div className="curve" />
        <div className="info">
          <img src={logo} alt="Logo Banco da Felicidade" />
          <h1>Cadastro alterado com Sucesso</h1>
          <CloseButton onClick={onClose}>Fechar</CloseButton>
        </div>
      </ConfirmSection>
    </Container>
  );
}

export default ConfirmacaoEditarUser;
