import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getUserData, updateUser } from "../../controllers/userController";
import GlobalStyle, {
  BackBar,
  BackButton,
  ErrorComponent,
  Spinner
} from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import {
  Aviso,
  ErrorMessage,
  Form,
  InputField,
  Label,
  RequiredText,
  SelectField,
  SubmitButton,
} from "./styles";

const UserEdit = () => {
  const user = JSON.parse(localStorage.getItem("user")); // Usuário autenticado
  const { id } = useParams(); // ID do usuário passado por parâmetro
  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [cpf, setCpf] = useState("");
  const [cargo, setCargo] = useState("");
  const [unidade, setUnidade] = useState("");
  const [secao, setSecao] = useState("");
  const [setor, setSetor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notFound, setNotFound] = useState(false);
  const isEditingOwnAccount = user.idusuario === parseInt(id, 10); // Verifica se está editando o próprio usuário
  const [showConfirmation, setShowConfirmation] = useState(false);
  
  useEffect(() => {
    if (user.nivel !== 1 && !isEditingOwnAccount) {
      alert("Você não tem permissão para editar este usuário.");
      navigate("/");
    }

    if (id) {
      getUserData(id)
        .then((data) => {
          if (!data) {
            setNotFound(true);
          } else {
            setNome(data.nome);
            setEmail(data.email);
            setCpf(data.cpf);
            setCargo(data.cargo);
            setUnidade(data.unidade);
            setSecao(data.secao);
            setSetor(data.setor);
          }
        })
        .catch((err) => setErrorMessage(err.message) && setNotFound(true));
    }
  }, [id, user.nivel, isEditingOwnAccount, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isEditingOwnAccount && senha !== confirmarSenha) {
      toast.error("As senhas não conferem.");
      return;
    }

    let formData = {
      nome,
      email,
      cpf,
      cargo,
      unidade,
      secao,
      setor,
    };

    if (isEditingOwnAccount && senha) {
      formData.senha = senha;
    }

    try {
      await updateUser(
        id,
        { nivelAuth: user.nivel, idAuth: user.idusuario },
        formData
      );
      toast.success("Usuário atualizado com sucesso!", {
        autoClose: 3000, 
        onClose: () => navigate("/"),
      });
      //navigate("/confirmacao-editar-usuario");
      //navigate(-1);
    } catch (error) {
      toast.error("Erro ao atualizar o usuário: " + error.mensagem);
    }
  };

  if (notFound) {
    return (
      <>
        <ToastContainer />
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Aviso light>
              <p>Úsuario não encontrado</p>
              <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Aviso>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #34C759 20%, #00A565 0%)`,
        }}
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        <Container direction="column" gap="40px" fullWidth>
          <BackBar>
            <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
            <BackButton onClick={() => navigate("/")}>X</BackButton>
          </BackBar>

          <ContentSection fullWidth padding="0px">
            <Content light padding="0px" sx={{ paddingTop: "40px" }}>
              <Form onSubmit={handleSubmit}>
                <p>Meu perfil</p>
                <Container
                  fullWidth
                  direction="column"
                  gap="14px"
                  padding="20px"
                >
                  <Label htmlFor="cpf">CPF</Label>
                  <InputField
                    type="text"
                    placeholder="CPF"
                    value={cpf}
                    disabled
                  />
                  <RequiredText>Obrigatório *</RequiredText>
                  <Label htmlFor="email">Email</Label>
                  <InputField
                    type="email"
                    placeholder="Email do usuário"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <RequiredText>Obrigatório *</RequiredText>

                  {isEditingOwnAccount && (
                    <>
                     
                      <InputField
                        type="password"
                        placeholder="Nova Senha"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>
                     
                      <InputField
                        type="password"
                        placeholder="Confirmar Senha"
                        value={confirmarSenha}
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>
                    </>
                  )}

                  {user.nivel == 1 && (
                    <>
                      <Label htmlFor="nome">Nome</Label>
                      <InputField
                        type="text"
                        placeholder="Nome do usuário"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        required
                      />
                      <RequiredText>Obrigatório *</RequiredText>

                      <Label htmlFor="nome">Cargo</Label>
                      <SelectField
                        value={cargo}
                        onChange={(e) => setCargo(e.target.value)}
                      >
                        <option value="">Selecione o Cargo</option>
                        <option value="Membro da Diretoria">
                          Membro da Diretoria
                        </option>
                        <option value="Gerente Geral">Gerente Geral</option>
                        <option value="Gerente Operacional">
                          Gerente Operacional
                        </option>
                        <option value="Gerente Corporativo">
                          Gerente Corporativo
                        </option>
                        <option value="Gestor">Gestor</option>
                        <option value="Coordenador Corporativo">
                          Coordenador Corporativo
                        </option>
                        <option value="Coordenador">Coordenador</option>
                        <option value="Supervisor">Supervisor</option>
                        <option value="Colaborador">Colaborador</option>
                      </SelectField>
                      <RequiredText>Obrigatório *</RequiredText>
                      <Label htmlFor="unidade">Unidade</Label>
                      <SelectField
                        value={unidade}
                        onChange={(e) => setUnidade(e.target.value)}
                      >
                        <option value="">Selecione a Unidade</option>
                        <option value="Holding">Holding</option>
                        <option value="Alegro">Alegro</option>
                        <option value="Alexânia">Alexânia</option>
                        <option value="Araxá">Araxá</option>
                        <option value="Atibaia">Atibaia</option>
                        <option value="Caeté">Caeté</option>
                      </SelectField>
                      <RequiredText>Obrigatório *</RequiredText>
                      <Label htmlFor="section">Seção</Label>
                      <InputField
                        type="text"
                        placeholder="Seção"
                        value={secao}
                        onChange={(e) => setSecao(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>
                      <Label htmlFor="nome">Setor</Label>
                      <InputField
                        type="text"
                        placeholder="Setor"
                        value={setor}
                        onChange={(e) => setSetor(e.target.value)}
                      />
                      <RequiredText>Obrigatório *</RequiredText>
                    </>
                  )}

                  {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
                  {errorMessage && (
                    <ErrorComponent message={errorMessage} duration={8000} />
                  )}
                  <SubmitButton
                    type="submit"
                    style={{ backgroundColor: "#FFCC00", alignSelf: "center" }}
                  >
                    Salvar
                  </SubmitButton>
                </Container>
              </Form>
            </Content>
          </ContentSection>
        </Container>
      </Container>
    </>
  );
};

export default UserEdit;
