import React, { useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { SvgSearch } from "../../assets/icons";
import iconGive from "../../assets/icons/icon-give.svg";
import iconHistory from "../../assets/icons/icon-history.svg";
import iconPrizes from "../../assets/icons/icon-prizes.svg";
import iconRanking from "../../assets/icons/icon-ranking.svg";
import logo from "../../assets/icons/logo-banco-da-felicidade.svg";
import placeholderImage from "../../assets/images/placeholder.png";
import Footer from "../../components/footer";
import { API_URL } from "../../controllers/api";
import { getPremios } from "../../controllers/premioController";
import { getUserData } from "../../controllers/userController";
import GlobalStyle, { IconInput } from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import { isDesktop, isTablet } from "../../utils";
import {
  Header,
  MenuContent,
  PremioItem,
  PremiosGrid,
  Spinner
} from "./styles";
const user = JSON.parse(localStorage.getItem("user"));

const PremioList = () => {
  const [userData, setUserData] = useState({});
  const [premios, setPremios] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [allPremios, setAllPremios] = useState([]); 
  
  const premiosPerPage = isDesktop() ? 9 : isTablet() ? 10 : 10; 

  useEffect(() => {
    const fetchInitialPremios = async () => {
      try {
        setIsLoading(true);
        const fetchedPremios = await getPremios(); 
        setAllPremios(fetchedPremios); 
        setPremios(fetchedPremios.slice(0, premiosPerPage)); 
        if (fetchedPremios.length <= premiosPerPage) setHasMore(false);
      } catch {
        setErrorMessage("Erro ao carregar os prêmios.");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchInitialPremios();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.idusuario) {
        const userInfo = await getUserData(user.idusuario);
        setUserData(userInfo);
      }
    };

    fetchUserData();
  }, []);

  

  useEffect(() => {
    const handleScroll = () => {
      if (
        !isLoading &&
        hasMore &&
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 200
      ) {
        loadMorePremios();
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, hasMore, premios]);


  const loadMorePremios = () => {
    const startIndex = premios.length;
    const endIndex = startIndex + premiosPerPage;
  
    if (startIndex >= allPremios.length) {
      setHasMore(false); 
      return;
    }
  
    const newPremios = allPremios.slice(startIndex, endIndex); 
    setPremios((prevPremios) => [...prevPremios, ...newPremios]); 
  };

  const filteredPremios = premios.filter((premio) =>
    premio.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  if (errorMessage || premios.length === 0) {
    return (
      <>
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Spinner />
            <span style={{ color: "black" }}>Procurando prêmios...</span>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Header>
        <div className="headerButtons">
          <img src={logo} alt="Logo Banco da Felicidade" className="logo" />
          <button>
            <FaRegBell />
          </button>
        </div>
        <div className="info">
          <FaCircleUser />
          <div className="userInfo">
            <h1 className="headerText">Bem-vindo de volta!</h1>
            <h2 className="userName">{userData.nome}</h2>
            <p className="userDetails">{userData.unidade}</p>
            <p className="userDetails">{userData.cargo}</p>
          </div>
          <div className="saldo">
            <h3 className="saldoTitle">Meu saldo</h3>
            <h2 className="saldoValue">{userData.saldo_moedas}</h2>
          </div>
        </div>
      </Header>
      <Container
        bgColor="#fff"
        fullWidth
        justify="center"
        align="center"
        direction="row"
        style={{ gap: "20px" }}
        position="relative"
      >
        <MenuContent>
          {user.nivel != 0 ? (
            <>
              <Link to="/dashboard">
                <img src={iconRanking} alt="Ranking" />
              </Link>
            </>
          ) : null}

          {user.nivel == 1 ? (
            <>
              <Link to="/distribuir-moedas">
                <img src={iconGive} alt="Destribuir" />
              </Link>
            </>
          ) : null}
          <Link to="/premios">
            <img src={iconPrizes} alt="Prêmios" />
          </Link>
          <Link to="/">
            <img src={iconHistory} alt="Históricos" />
          </Link>
        </MenuContent>
      </Container>
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        justify="center"
        padding="0px"
      >
        <ContentSection fullWidth style={{ paddingBlock: "50px" }}>
          <Content style={{ gap: "10px" }}>
            <h1
              style={{
                color: "black",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Premios
            </h1>
            <IconInput>
              <SvgSearch />
              <input
                type="text"
                placeholder="Pesquisar prêmios"
                value={searchTerm}
                onChange={handleSearch}
              />
            </IconInput>
            <PremiosGrid>
              {filteredPremios.map((premio) => (
                <PremioItem
                  key={premio.idpremio}
                  onClick={() => navigate(`/premios/premio/${premio.idpremio}`)}
                >
                  <div>
                    <img
                      src={API_URL + premio.url_imagem || placeholderImage}
                      alt={premio.nome}
                    />
                    <h5>{premio.nome}</h5>
                    <p>
                      Valor <strong>{premio.valor}</strong>
                    </p>
                  </div>
                  <button className="queroButton">Quero</button>
                </PremioItem>
              ))}

              {isLoading && (
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <Spinner />
                  <p style={{ color: "black" }}>Carregando mais prêmios...</p>
                </div>
              )}
            </PremiosGrid>
            {isLoading && <Spinner />}
          </Content>
        </ContentSection>
        <Footer />
      </Container>
    </>
  );
};

export default PremioList;
