import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../../components/header";
import { Container, Content, ContentSection } from "../../styles/styles";
import GlobalStyle, {
  BackBar,
  BackButton,
  ErrorComponent,
} from "../../styles/global";
import { Aviso } from "./styles";
import { getUserData } from "../../controllers/userController";
import { FaRegUser, FaRegUserCircle } from "react-icons/fa";
import { GrGift } from "react-icons/gr";
const UserDetails = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const id = user.idusuario;
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [cargo, setCargo] = useState("");
  const [unidade, setUnidade] = useState("");
  const [secao, setSecao] = useState("");
  const [setor, setSetor] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [notFound, setNotFound] = useState(false);

  const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    /* margin-top: 24px; */
  `;

  const ActionButton = styled.div`
    background-color: ${(props) =>
      props.type === "add"
        ? "#00bcd4"
        : props.type === "edit"
        ? "#ff9800"
        : "#f44336"};
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 24px;
    border-radius: 12px;

    > p {
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
    }
  `;

  useEffect(() => {
    if (user.nivel !== 1) {
      alert(
        "Você não tem permissão para acessar as configurações. Entre em contato com o administrador."
      );
      navigate("/");
    }
    if (id) {
      getUserData(id)
        .then((data) => {
          if (!data) {
            setNotFound(true); // Se o prêmio não for encontrado, atualiza o estado
          } else {
            setNome(data.nome);
            setEmail(data.email);
            setCpf(data.cpf);
            setCargo(data.cargo);
            setUnidade(data.unidade);
            setSecao(data.secao);
            setSetor(data.setor);
          }
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [id]);

  if (notFound) {
    return (
      <>
        <Header />
        <GlobalStyle />
        <ContentSection fullWidth height="100vh">
          <Content direction="column" align="center">
            <Aviso light>
              <p>Úsuario não encontrado</p>
              <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
              toast.error("CPF ou email inválido. Por favor, tente novamente.");
            </Aviso>
          </Content>
        </ContentSection>
      </>
    );
  }

  return (
    <>
      <GlobalStyle />

      <Container
        style={{
          background: `linear-gradient(10deg, #34C759 20%, #00A565 0%)`,
        }}
        fullWidth
        direction="column"
        align="center"
        justify="center"
        height="100%"
      >
        <BackBar
          style={{
            position: "fixed",
            top: "10px",
            left: 0,
            width: "100%",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
          <BackButton onClick={() => navigate("/")}>X</BackButton>
        </BackBar>

        <ContentSection fullWidth>
          <Content light>
            <Container direction="column" padding="0">
              {/*  <Link to={`/perfil/editar/${id}`}>

              
                <EmployeeActionItem>
                  <Container gap="20px" align="center" padding="0">
                    <div className="employee-icon-div">
                      <SvgUser className="employee-icon" />
                    </div>
                    <p>Minha Conta</p>
                  </Container>
                  <FaChevronRight />
                </EmployeeActionItem>
              </Link>*/}

              {user.nivel == 1 ? (
                <>
                  <ButtonGrid>
                    <Link to={`/perfil/novo`}>
                      <ActionButton type="add">
                        <FaRegUserCircle size={40} />
                        <p>Novo Cadastro</p>
                      </ActionButton>
                    </Link>
                    <Link to={`/premios/premio/novo`}>
                      <ActionButton type="add">
                        <GrGift size={40} />
                        <p>Criar Prêmio</p>
                      </ActionButton>
                    </Link>

                    <Link to={`/usuario/editar/:id"`}>
                      <ActionButton type="edit">
                      <FaRegUserCircle size={40} />
                        <p>Editar Cadastro</p>
                      </ActionButton>
                    </Link>

                    <Link to={`/premios/premio/editar/:id`}>
                      <ActionButton type="edit">
                        <GrGift size={40} />
                        <p>Editar Prêmio</p>
                      </ActionButton>
                    </Link>

                    <Link to={`/usuarios/remover`}>
                      <ActionButton type="remove">
                        <FaRegUserCircle size={40} />
                        <p>Remover Cadastro</p>
                      </ActionButton>
                    </Link>
                    <Link to={`/premios/remover`}>
                      <ActionButton type="remove">
                        <GrGift size={40} />
                        <p>Remover Prêmio</p>
                      </ActionButton>
                    </Link>
                  </ButtonGrid>
                </>
              ) : null}
            </Container>

            {/*  <LogoutButton onClick={logout}>Sair</LogoutButton> */}

            <Container direction="column" width="80%" gap="20px"></Container>

            {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
            {errorMessage && (
              <ErrorComponent message={errorMessage} duration={8000} />
            )}
          </Content>
        </ContentSection>
      </Container>
    </>
  );
};

export default UserDetails;
