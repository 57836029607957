import React, { useEffect, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import { API_URL } from "../../controllers/api";
import {
  getPremioById,
  updatePremio,
} from "../../controllers/premioController";
import GlobalStyle, { BackBar, BackButton } from "../../styles/global";
import { Container, Content, ContentSection } from "../../styles/styles";
import * as styles from "./styles";
import { Label } from "../user/styles";

const SubmitButton = styled.button`
  padding: 10px 70px;
  border-radius: 30px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const FileInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  cursor: pointer;

  input {
    display: none;
  }

  svg {
    height: 30px;
    color: #000;
  }

  img {
    width: 60%;
    border-radius: 8px;
  }

  .interna {
    background-color: #f7f7f7;
    padding-inline: 8px;
    padding-block: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .interna span {
    color: #000;
    width: 80%;
  }
`;

const EditarPremio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [estoque, setEstoque] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (id) {
      getPremioById(id)
        .then((data) => {
          if (!data) {
            setNotFound(true);
          } else {
            setTitle(data.nome);
            setValue(data.valor);
            setEstoque(data.estoque);
            setDescription(data.descricao);
            setPreviewImage(API_URL + data.url_imagem);
            setOriginalImage(data.url_imagem);
          }
        })
        .catch((err) => setErrorMessage(err.message));
    }
  }, [id]);

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleValueChange = (e) => setValue(e.target.value);
  const handleEstoqueChange = (e) => setEstoque(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nome", title);
    formData.append("valor", value);
    formData.append("estoque", estoque);
    formData.append("descricao", description);
    if (image) {
      formData.append("url_imagem", image);
    } else {
      formData.append("url_imagem", originalImage);
    }

    try {
      await updatePremio(id, formData);
      toast.success("Prêmio atualizado com sucesso!", {
        autoClose: 3000,
        onClose: () => navigate("/premios"),
      });
    } catch (error) {
      toast.error("Erro ao atualizar o prêmio. " + error.message);
    }
  };

  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Container
        style={{
          background: `linear-gradient(10deg, #FF2D55 20%, #30B0C7  0%)`,
        }}
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        <BackBar>
          <BackButton onClick={() => navigate("/premios")}>← Voltar</BackButton>
        
          <BackButton onClick={() => navigate("/")}>X</BackButton>
        </BackBar>

        <ContentSection fullWidth padding="0px">
          <Content light padding="0px" sx={{ paddingTop: "40px" }}>
            <form onSubmit={handleSubmit}>
              <h2>Editar Prêmio</h2>

              <Container fullWidth direction="column" gap="18px" padding="20px">
              <Label htmlFor="name">Nome</Label>
                <styles.InputField
                  type="text"
                  placeholder="Nome do prêmio"
                  value={title}
                  onChange={handleTitleChange}
                  required
                />
                <styles.RequiredText>Obrigatório *</styles.RequiredText>
                <Label htmlFor="value">Valor</Label>
                <styles.InputField
                  type="number"
                  placeholder="Valor do prêmio"
                  value={value}
                  onChange={handleValueChange}
                  required
                />
             
                <styles.RequiredText>Obrigatório *</styles.RequiredText>
                <Label htmlFor="stock">Estoque</Label>
                <styles.InputField
                  type="number"
                  placeholder="Estoque"
                  value={estoque}
                  onChange={handleEstoqueChange}
                  required
                />
              
                <styles.RequiredText>Obrigatório *</styles.RequiredText>
                <Label htmlFor="description">Descrição</Label>
                <styles.TextArea
                  placeholder="Descrição"
                  value={description}
                  onChange={handleDescriptionChange}
                  required
                />
               

                <styles.RequiredText>Obrigatório *</styles.RequiredText>
                <Label htmlFor="image">Imagem</Label>
                <FileInput>
                  <div className="interna">
                    <span>Clique para alterar a imagem</span>
                    <IoCloudUploadOutline />
                    <input type="file" onChange={handleFileChange} />
                  </div>
                  {previewImage && (
                    <img src={previewImage} alt="Pré-visualização da imagem" />
                  )}
                </FileInput>
                <styles.RequiredText>Obrigatório *</styles.RequiredText>

                <SubmitButton
                  type="submit"
                  style={{ backgroundColor: "#FFCC00", alignSelf: "center" }}
                >
                  Confirmar
                </SubmitButton>
              </Container>
            </form>
          </Content>
        </ContentSection>
      </Container>
    </>
  );
};

export default EditarPremio;
