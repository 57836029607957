import { useState } from "react";
import { FiXCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { deleteUser } from "../controllers/userController";
import GlobalStyle, { Border, Container } from "../styles/global";
import { ContentSection } from "../styles/styles";
import Footer from "./footer";

export const SubmitButton = styled.button`
  background-color: #ff2d55;
  padding: 10px 70px;
  border-radius: 30px;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export const Header = styled.header`
  background: linear-gradient(185deg, #ffcc00 28%, #ff9500 0%);
  color: white;
  padding: 14px;
  padding-bottom: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  position: relative;

  .logo {
    width: 80px;
  }

  .headerButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 50px;
    }

    > button {
      background-color: transparent;

      > svg {
        height: 20px;
      }
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    > svg {
      width: 100px;
      height: 50px;
    }

    .userInfo {
      text-align: left;

      .headerText {
        font-size: 12px;
        font-weight: 300;
      }

      .userName {
        font-size: 16px;
      }

      .userDetails {
        font-size: 15px;
      }
    }

    .saldo {
      width: 80%;
      color: white;
      padding: 10px;
      border: 1px solid #fff;
      border-radius: 10px;
      text-align: center;

      .saldoTitle {
        font-size: 14px;
        font-weight: 300;
      }

      .saldoValue {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
`;

const InfoContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 80px 20px 70px; ;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 20px;
  gap: "15px";
  background-color: #fff;
  border: 1px solid ${Border};
  border-radius: 8px;
  

  .simpleText {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #000;
  
    
  }
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;
const UserRemove = ({emocionador, handleDelete}) => {
  const navigate = useNavigate();
  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <Header style={{ paddingTop: "28px", textAlign: "center" }}>
      <BackButton onClick={() => navigate(-1)}>← Voltar</BackButton>
        <div style={{ padding: "12px" }}>
          <h3>Exclusão de Usuário!</h3>
          <p>Confirme os dados do emocionador que deseja remover!</p>
        </div>
      </Header>
      <Container
        bgColor="#fff"
        direction="column"
        align="center"
        justify="center"
        height="100%"

      >
        <ContentSection
          maxWidth="500px"
          direction="column"
          gap="18px"
          padding="20px"
        >
          <InfoContainer
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div>
              <p style={{ color: "red" }}>Emocionador</p>
              <p className="simpleText">{emocionador.nome || "Usuário"}</p>
            </div>

            <div>
              <p style={{ color: "red" }}>Email</p>
              <p className="simpleText">{emocionador.email || "Email"}</p>
            </div>

            <div>
              <p style={{ color: "red" }}>CPF</p>
              <p className="simpleText">{emocionador.cpf || "CPF"}</p>
            </div>

            <div>
              <p style={{ color: "red" }}>Saldo</p>
              <p className="simpleText">
                {emocionador.saldo_moedas || "Saldo"}
              </p>
            </div>

            <div style={{ display: "flex", gap: "20px" }}>
              <div>
                <p style={{ color: "red" }}>Unidade</p>
                <p className="simpleText">
                  {emocionador.unidade || "N/A"}
                </p>
              </div>

              <div>
                <p style={{ color: "red" }}>Seção</p>
                <p className="simpleText">{emocionador.setor || "N/A"}</p>
              </div>
            </div>
          </InfoContainer>


          <SubmitButton type="submit" onClick={()=> handleDelete(emocionador.id)}>
            Remover
          </SubmitButton>
        </ContentSection>
        <Footer />
      </Container>
    </>
  );
};

export default UserRemove;
