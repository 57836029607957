import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container } from '../styles/styles';
import { PrimaryColor, SecondaryColor, TextColor, TextColorLight, Accent, ErrorComponent, Border, Blue, Spinner } from '../styles/global';
import { IconInput } from '../styles/global';
import { ContentSection, Content } from '../styles/styles';
import {  SvgSearch } from '../assets/icons';
import { getEmocionadoresRecentes, getEmocionadores } from '../controllers/emocionarController';
import { FaRegUserCircle } from 'react-icons/fa';
import { FiXCircle } from 'react-icons/fi';
import logo from "../assets/icons/logo-banco-da-felicidade.svg";

const Header = styled.header`
  height: 130px;
  background: linear-gradient(185deg, ${SecondaryColor} 28%, ${Blue} 0%);
  color: white;
  padding: 14px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 18px;
  position: relative;
      
    .logo {
      left: 10px;
      top:10px;
      position: absolute;
      align-items: start;
      width: 50px;
    }
      
    .headerText {
      margin-top: 18px;
      text-align: center;
      font-size: 18px;
      font-weight: normal;
    }
    
`;

const BackButton = styled.a`
  margin-top: 22px;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  width: fit-content;
`;

const SearchInput = styled(IconInput)`
  position: absolute;
  top: -20px;
`;

const EmployeeAll = styled(Container)`
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;

    h3 {
    color: ${PrimaryColor};
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }
`;

const RecentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;

  > h2 {
    color: ${PrimaryColor};
    font-size: 18px;
    font-weight: 400;
    text-align: start;
  }
`;

const EmployeeList = styled(Container)`
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  gap: 22px;
`;

const EmployeeItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h4{width: fit-content;}
  p{width: fit-content;}
  small{width: fit-content;}

  .employee-icon-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: ${Border};
    text-align: center;
    padding: 10px;
  }
  .employee-icon {
    font-size: 25px;
    color: #444;
  }

  .employee-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #000;
    text-align: left;
    flex:1;

    h5 {
      color: #000;
      font-size: 14px;
      font-weight: 300;
    };
  }

  .employee-details {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    text-align: right;
    flex:0.5;

    p, small{
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
    }

    p{font-weight: 500; color: #000;}
    small{font-weight: lighter; color: #7E8392;}
  }
`;

function SelecionarEmocionadorAdm({ onSelect, onClose }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [emocionadoresRecentes, setEmocionadoresRecentes] = useState([]);
  const [emocionadores, setEmocionadores] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true); 
  const usuarioId = JSON.parse(localStorage.getItem('user')).idusuario; // ID do usuário logado

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar emocionadores recentes
        const recentData = await getEmocionadoresRecentes(usuarioId);
        setEmocionadoresRecentes(recentData);

        // Buscar todos os emocionadores
        const allData = await getEmocionadores(usuarioId);
        setEmocionadores(allData);
      } catch (error) {
        setErrorMessage("Erro ao carregar emocionadores.");
      } finally {
        setIsLoading(false); 
      }
    };

    fetchData();
  }, [usuarioId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",  
          backgroundColor: "#f5f5f5", 
        }}
      >
        <Spinner />
        <span
          style={{
            color: "black",
            marginTop: "10px", 
            fontSize: "16px", 
          }}
        >
          Carregando emocionadores...
        </span>
      </div>
    );
  }

   // Função para remover acentuação
   const removeAcentos = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredEmocionadores = emocionadores.filter(emocionador =>
    removeAcentos(emocionador.nome.toLowerCase()).includes(removeAcentos(searchTerm.toLowerCase()))
  );

  return (
    <>
    <Header>
        <img src={logo} alt="Logo Banco da Felicidade" className="logo" />
        <h1 className="headerText">Distribuir Moedas</h1>
        <BackButton onClick={onClose}><FiXCircle/></BackButton>
    </Header>
    <Container direction="column" width="100%" >
      <ContentSection fullWidth padding="10px">
        <Content padding="0px" position="relative" >
          <SearchInput>
            <SvgSearch />
            <input
              placeholder="Pesquisar por nome ou unidade"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              />
          </SearchInput>

          {emocionadoresRecentes.length > 0 && (
            <RecentList>
              <h2>Emocionadores Recentes</h2>
              <EmployeeList>
                {emocionadoresRecentes.map(emocionador => (
                  <EmployeeItem key={emocionador.idusuario} onClick={() => onSelect(emocionador)}>
                    <EmployeeInfo>
                      <div className='employee-name'>
                        <div className="employee-icon-div">
                          <FaRegUserCircle className="employee-icon" />
                        </div>
                        <h5>{emocionador.nome}</h5>
                      </div>
                      <div className="employee-details">
                        <p>{emocionador.unidade}</p>
                        <small>{emocionador.setor}</small>
                      </div>
                    </EmployeeInfo>
                  </EmployeeItem>
                ))}
              </EmployeeList>
            </RecentList>
          )}

          <EmployeeAll light padding="0px">
            <h3>Todos os Emocionadores</h3>
            <EmployeeList light>
              {filteredEmocionadores.map(emocionador => (
                <EmployeeItem light key={emocionador.idusuario} onClick={() => onSelect(emocionador)}>
                  <EmployeeInfo light>
                    <div className='employee-name'>
                      <div className="employee-icon-div">
                      <FaRegUserCircle className="employee-icon" />
                      </div>
                      <h5>{emocionador.nome}</h5>
                    </div>
                    <div className="employee-details">
                      <p>{emocionador.unidade}</p>
                      <small>{emocionador.setor}</small>
                    </div>
                  </EmployeeInfo>
                </EmployeeItem>
              ))}
            </EmployeeList>
          </EmployeeAll>

          {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}
          {errorMessage && <ErrorComponent message={errorMessage} duration={8000} />}
          
        </Content>
      </ContentSection>
    </Container>
    </>
  );
}

export default SelecionarEmocionadorAdm;
