import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ConfirmacaoEditarUser from "../../components/ConfirmacaoEditarUder";
import ListaEmocionadorEditar from "../../components/listUserEdit";
import {
  getUserData
} from "../../controllers/userController";
import GlobalStyle, { Container } from "../../styles/global";
import UserEdit from "./edit";

const BackBar = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  max-width: 100%;
`;

function EditAllUser({ onClose }) {
  const [step, setStep] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (id) {
      getUserData(id)
        .then((data) => {
          if (!data) {
            setNotFound(true);
          } else {
            setSelectedUser(data);
          }
        })
        .catch(() => setNotFound(true));
    }
  }, [id]);

  useEffect(() => {
    if (selectedUser.id) {
    
      navigate(`/perfil/editar/${selectedUser.id}`);  
    }
  }, [selectedUser, navigate]);

  const handleSelectEmocionador = (emocionador) => {
    setSelectedUser(emocionador);
    setStep(2);
  };

  return (
    <>
      <GlobalStyle />
      <Container
        bgColor="#fff"
        fullWidth
        direction="column"
        align="center"
        padding="0px"
        height="100%"
      >
        {step === 1 && (
          <ListaEmocionadorEditar
            onSelect={handleSelectEmocionador}
            onClose={() => navigate("/")}
          />
        )}
        {step === 2 && (
          <UserEdit emocionador={selectedUser} />
        )}
        {step === 3 && <ConfirmacaoEditarUser onClose={() => navigate("/")} />}
      </Container>
    </>
  );
}

export default EditAllUser;
